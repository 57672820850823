.container.header {
  display: none;
}

.url.breadcrumbs {
  display: none;
}

.landingStyles {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-bottom: -100px;
  p {
    margin: 4.5vw 0 0px 0;
  }

  .firstColumn {
    padding: 2.4vw 0 0 3vw;
    height: calc(100vh - 84px);
    div {
      padding-left: 3px !important;
    }
  }

  .secondColumn {
    text-align: right;
    padding: 3.5vw 2vw 2vw 0;
    p {
      margin: 0 1vw 0.5vw 0;
    }
    .secondColumnTitle {
      padding-right: 1vw;
    }
    .secondColumnSubtitle {
      margin-bottom: 2.5vw !important;
      padding-right: 1vw;
    }
  }

  .countryCardsColumns {
    margin: 0 0 0 3vw;
    padding: 0;
  }

  // DRC CARD:

  .countryCardDrc{
    min-width: 363.59px;
    min-height: 160px;
    margin: 0;
    padding: 15px 1vw 0.4vw 0.4vw !important;
    background-color: #FFFFFF;
    border-radius: 4px !important;
    text-align: left;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);

    img {
      max-width: 100px;
      padding-top: 0.5vw;
    }
    .countryCopy {
      line-height: 110%;
      padding: 0.4vw 0 0.6vw 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .learnMore{
      background-color: transparent;
      height: 26px;
      width: 90px;
      border-radius: 3px;
      text-align: center;
      padding-top: 0.3vw;
      transform: translateX(-0.2vw);
      }
  }
  .countryCardDrc:hover{
    .learnMore{
      background-color: #FDEFF0;
    }
  }

  // ETHIOPIA CARD:

  .countryCardEthiopia{
    min-width: 363.59px;
    min-height: 160px;
    margin: 0;
    padding: 15px 1vw 0.4vw 0.4vw !important;
    background-color: #FFFFFF;
    border-radius: 4px !important;
    text-align: left;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    img {
      max-width: 100px;
      padding: 0.5vw 0 0 0;
    }
    .countryCopy {
      line-height: 110%;
      padding: 0.4vw 0 0.6vw 0;
    }
    .learnMore{
      background-color: transparent;
      height: 26px;
      width: 90px;
      border-radius: 3px;
      text-align: center;
      padding-top: 0.3vw;
      transform: translateX(-0.2vw);
    }
  }
  .countryCardEthiopia:hover{
    .learnMore{
      background-color: #CCE5E8;
    }
  }

  // KENYA CARD:

  .countryCardKenya{
    min-width: 363.59px;
    min-height: 160px;
    margin: 0;
    padding: 15px 1vw 0.4vw 0.4vw !important;
    background-color: #FFFFFF;
    border-radius: 4px !important;
    text-align: left;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    img {
      max-width: 100px;
      padding: 0.5vw 0 0 0;
    }
    .countryCopy {
      line-height: 110%;
      padding: 0.4vw 0 0.6vw 0;
    }
    .learnMore{
      background-color: transparent;
      height: 26px;
      width: 90px;
      border-radius: 3px;
      text-align: center;
      padding-top: 0.3vw;
      transform: translateX(-0.2vw);
    }
  }
  .countryCardKenya:hover{
    .learnMore{
      background-color: #E0D6E4;
    }
  }

  // NIGERIA CARD:

  .countryCardNigeria{
    min-width: 363.59px;
    min-height: 160px;
    margin: 0;
    padding: 15px 1vw 0.4vw 0.4vw !important;
    background-color: #FFFFFF;
    border-radius: 4px !important;
    text-align: left;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    img {
      max-width: 100px;
      padding: 0.5vw 0 0 0;
    }
    .countryCopy {
      line-height: 110%;
      padding: 0.4vw 0 0.6vw 0;
    }
    .learnMore{
      background-color: transparent;
      height: 26px;
      width: 90px;
      border-radius: 3px;
      text-align: center;
      padding-top: 0.3vw;
      transform: translateX(-0.2vw);
    }
  }
  .countryCardNigeria:hover{
    .learnMore{
      background-color: #DCEEE0;
    }
  }

  // SOUTH AFRICA CARD:

  .countryCardSouthAfrica{
    min-width: 363.59px;
    min-height: 160px;
    margin: 0;
    padding: 15px 1vw 0.4vw 0.4vw !important;
    background-color: #FFFFFF;
    border-radius: 4px !important;
    text-align: left;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    img {
      max-width: 100px;
      padding: 0.5vw 0 0 0;
    }
    .countryCopy {
      line-height: 110%;
      padding: 0.4vw 0 0.6vw 0;
    }
    .learnMore{
      background-color: transparent;
      height: 26px;
      width: 90px;
      border-radius: 3px;
      text-align: center;
      padding-top: 0.3vw;
      transform: translateX(-0.2vw);
    }
  }
  .countryCardSouthAfrica:hover{
    .learnMore{
      background-color: #DEEFEA;
    }
  }

  // ZAMBIA CARD:

  .countryCardZambia{
    min-width: 363.59px;
    min-height: 160px;
    margin: 0;
    padding: 15px 1vw 0.4vw 0.4vw !important;
    background-color: #FFFFFF;
    border-radius: 4px !important;
    text-align: left;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    img {
      max-width: 100px;
      padding: 0.5vw 0 0 0;
    }
    .countryCopy {
      line-height: 110%;
      padding: 0.4vw 0 0.6vw 0;
    }
    .learnMore{
      background-color: transparent;
      height: 26px;
      width: 90px;
      border-radius: 3px;
      text-align: center;
      padding-top: 0.3vw;
      transform: translateX(-0.2vw);
    }
  }
  .countryCardZambia:hover{
    .learnMore{
      background-color: #FCE2CC;
    }
  }

  .is-layout-flow .wp-block-column{
    padding: 0.7vw;
  }

}

