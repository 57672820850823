@import '../vars';

.edit-post-visual-editor, #root {
  @import '../default/fonts';
  @import '../default/illicit';
  @import '../default/tabbed';
  @import '../default/body';
  @import '../default/gallery';
  @import '../default/myths';
  @import '../default/responsive';
  @import '../default/featured';
  @import '../default/verticalFeatured';
  @import '../default/inline-list';
  @import '../default/charts';
  @import '../default/carousel';
  @import '../default/pagemodules';
  @import "../default/showCase";
  @import "../default/download";
  @import "../default/downloadpdf";
  @import "../default/filters";
  @import "../default/map";
  @import "../default/dataFiltersReset";
  @import "../default/tooltip";
  @import "../default/references";
  @import "../default/userMeasures";
  @import "../default/chartNoDataMessage";
  @import "../default/measuresGroup";
  @import "../default/language";
  @import "../default/header";
  @import "footer";
  @import "backtotop";
  @import "../default/content";
  @import "../default/newsLetter";
  @import "../default/floating";
  @import "../default/pagemodules";
  @import "../default/colors";
  @import "../default/search";
  @import "../default/float_search";
  @import "../default/timeline";
  @import "../default/agreeAndDownload";
  @import "landingStyles";

}



