.vertical.featured.tabs {

  .item {
    overflow: hidden;
    flex-grow: 1;
  }


  .item.expanded {
    flex-grow: 1;
    transition: all .7s;
  }

  .item.collapsed {
    flex-grow: 0;
    transition: all .7s;
  }


  .content-area {
    height: 100%;
    display: block;
    position: relative;

    .cover {
      cursor: pointer;
      padding: 0px;
      left: 0px;
      float: left;
      position: absolute;
      height: 100%;

      .rotator {
        position: absolute;
        transform-origin: left;

        * {
          margin: 0px;
          padding: 0px;
        }

      }
    }

    .collapsable-content {
      .entry-content {
        height: 100%;
        margin: auto;
        padding: 0px;
        .ui.fluid.container.content{
          height: 100%;
          overflow-y: auto;
          &::-webkit-scrollbar-thumb {
            @include border-radius(4px);
						background-color: $gray;
					}
					&::-webkit-scrollbar-track {
            @include border-radius(0);
						background-color: $light-gray;
					}
        }
      }

      height: 100%;
      position: absolute;
      display: block;
      margin-right: 0px;

    }

  }


}
