
body {
  background-color: transparent !important;
}

.map.component {
  position: relative;
  background-color: transparent;
  overflow: hidden;
  .legend {

    background-color: #FFF;
    margin-top: 4px;
    overflow: hidden;
    @media only screen and (max-width: 414px) {
      margin-top: 10px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0 0 0 10px;
      width: 100%;

      li {
        display: inline-flex;
        float: left;
        margin-bottom: 10px;
        margin-right: 10px;

        .legend-label {
          color: #66676d;
          display: block;
          float: left;
          font-weight: 400;
          font-size: 15px;
          line-height: 12px;
          margin-top: 5px;
          margin-left: 5px;
          white-space: nowrap;
        }

        .symbol {
          float: left;
          height: 15px;
          margin-top: 3px;
          width: 15px;
        }
      }
    }
  }

  .edit-mode-message {
    border: 1px solid #1e1e1e;
    padding: 10px;
    border-radius: 2px;
    margin: 10px;
  }

  .control.panel {
    position: absolute;
    bottom: 8vh;
    margin-left: 0.5vw;

    .reset,
    .zoom {
      padding: 0.2vw;
      margin-top: 0.2vw;
      height: auto !important;
      width: auto !important;
    }

    .icon {
      background-color: $blue;
      padding: 5px;
      width: 30px;
      height: 25px;
      font-size: 14px;
      color: #FFF;
    }
  }

  .ocean-color {
    fill: #c1e1ec;
  }

  rect.country-map-label {
    fill: #5baf95;
  }

  circle.active {
    fill: #FFF;
    stroke: #5baf95;
    stroke-width: 2;
  }

  text {
    tspan.label {
      font-weight: bold;
      fill: #5baf95;
      pointer-events: all;
    }
  }

  text.focus {
    tspan.label {
      color: #FFF;
      fill: #FFF !important;
    }
  }

  path.zoom-enabled {
    cursor: pointer;
  }

  path.focus {
    opacity: 0.8;
  }

  circle.focus {
    stroke: #67a0bd;
    fill: #84acc0;
  }

  .big.label {
    font-size: 40px;
    text-transform: uppercase;
    fill: #FFF;
  }

  .map-label {
    fill: #595959;
    @media print{
      font-size: 14px;
      font-weight: 600 !important;
    }
  }


  .map-label-bg {
    fill:  #fff6e1;
  }

  .map-label-no-bg {
    opacity: 0;
  }

  .map-label-value {
    font-weight: 700;
  }

  .big.numeric.label {
    padding: 0;
    font-size: 140px;
    fill: #5baf95;
    font-weight: bold;
  }

  .hidden {
    display: none;
  }

  .national-avg-value {
    color: #ffb400;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
  }

  .national-avg-label {
    margin-left: 5px;
    font-size: 14px;
    color: #66676d;
    font-weight: bold;
    @media print{
      white-space:nowrap;
    }
  }

  .footnote {

    margin: 0;
    min-height: none;
    padding: 0;
    text-align: left;
    width: auto;

    @media print{
      padding-left: 10px;
    }

    p {
      line-height: 40px;
      font-size: 13px;
      padding: 0px;
      margin-left: 10px;
      margin-right: 10px;
      text-align: right;
    }
  }


  .legend-title {

    float: left;
    text-align: right;
    font-size: 14px;
    color: #66676d;
    font-weight: bold;
    margin-right: 10px;
    white-space: nowrap;
  }

  .vertical-spacer{
    color: #dadada;
    font-weight: bold;
    margin-top: -3px;
  }

  .highlighted-loc-info {
    width: 20%;
	  border-radius: 5px;
	  padding: 5px;
    span {
      font-weight: bold;
    }
    top: 45px;
    right: 15px;
    position: absolute;
  }

  .measure-selector {
    margin-top: 5px;
    .label {
      font-weight: bold;
    }
    ul {
      margin: 5px auto 0 $base-unit;
      overflow: hidden;
    }
    li {
      display: inline-flex;
      float: left;
      margin-bottom: 5px;
      margin-right: 10px;
      font-size: 14px;
      padding: 5px 5px 5px 0px;
      color: #66676d;
      cursor: pointer;
      @media print{
        white-space:nowrap;
      }

      input[type=radio] {
        margin-top: 0.1rem;
        margin-bottom: 0.25rem;
      }
    }

  }



}
