
.chart.container {

  .ui.segment.nodata {
    border-top: 0px solid #db2828 !important;
    margin-top: 60px;
    margin-left: -5px;
    vertical-align: center;
    border: 0px !important;
    font-size: 20px;
    text-align: center;
    background-color: #CCC;
  }

  text {
    //border: 1px solid red !important;
    fill: #FFF;
  }

  margin: 0px;
  padding: 0px;

  .body {
    position: relative;
    height: 100%;
    padding: 5px;
    margin: 0px;

    .ui.container.entry-content {
      padding: 0px !important;
      margin: 0px !important;;

    }

    svg {
      pointer-events: all;
      overflow: visible;

      text {
        font-size: 13px !important;
      }
    }
  }

  .legend.item {
    input[type=checkbox] {
      width: 18px;
      height: 18px;
    }

    input[type=checkbox]:before, input[type=radio] {

      width: 16px;
      margin: auto !important;
      font-size: 16px !important;
    }

    label {
      padding: 3px;
      font-size: 14px !important;
    }
  }

  .legends.container.left {
    position: absolute;
    bottom: 50px;
    left: 10px;

    .legend.item {

      margin: 2px;
      padding: 3px;
      text-align: left;
      display: flex;
      line-height: 28px !important;

      input[type=checkbox], input[type=radio] {
        display: none;
      }

      label {
        padding: 0px 5px;
        line-height: 20px;
        height: 20px;

      }
    }
  }

  .legend-title {
    color: #66676d;
    cursor: default;
    font-weight: bold;
    font-size: 14px;
  }

  .legends.container.top, .legends.container.left, .legends.container.right, .legends.container.bottom {
    .legend.item {
      .checkmark-with-bg, .checkmark {
        height: 18px;
        width: 18px;
        margin-right: 4px;
        border: 1px solid #8c8f94;
        border-radius: 4px;
        cursor: pointer;
      }

      input:checked ~ .checkmark:after, input:checked ~ .checkmark-with-bg:after {
        display: block;
      }

      .checkmark:after, .checkmark-with-bg:after {
        content: "";
        display: none;
      }

      .checkmark:after {
        position: relative;
        left: 5px;
        top: 2px;
        width: 7px;
        height: 10px;
        border: solid #2196F3;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .checkmark-with-bg:after {
        position: relative;
        left: 5px;
        top: 2px;
        width: 7px;
        height: 10px;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .label {
        cursor: pointer;
      }
    }
  }

  .legends.container.left {
    .legend.item {
      .legend-title {
        padding-left: 0px;
      }
    }
  }

  .legends.container.right {
    position: absolute;
    bottom: 50px;
    right: 10px;

    .legend.item {
      margin: 2px;
      padding: 3px;
      text-align: left;
      display: flex;
      line-height: 28px !important;

      input[type=checkbox], input[type=radio] {
        display: none;
      }

      label {
        padding: 0px 5px;
        line-height: 20px;
        height: 20px;

      }
      .legend-title {
        padding-left: 0px;
      }
    }
  }

  .legends.container.top {
    position: absolute;
    top: 5px;
    left: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    //flex-wrap: wrap;

    .legend-sections {
      display: flex;
      .title-section {
        display: flex;
        position: relative;
      }
      .items-section{
      display: flex;
      position: relative !important;
      flex-wrap: wrap !important;
    }
    }
    .legend.item {
      margin: 2px;
      padding: 3px;
      text-align: left;
      display: flex;
      line-height: 28px !important;

      input[type=checkbox], input[type=radio] {
        display: none;
      }

      label {
        padding: 0px 3px;
        line-height: 20px;
        height: 20px;
        white-space: nowrap;
      }


    }

    .legend.item.overlay {
      margin-left: 20px;
    }
  }

  .legends.container.bottom {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    //flex-wrap: wrap;

    .legend-sections {
      display: flex;
      .title-section {
        display: flex;
        position: relative;
      }
      .items-section{
      display: flex;
      position: relative !important;
      flex-wrap: wrap !important;
    }
    }
    .legend.item {
      margin: 2px;
      padding: 3px;
      text-align: left;
      display: flex;
      line-height: 28px !important;

      input[type=checkbox], input[type=radio] {
        display: none;
      }

      label {
        padding: 0px 5px;
        line-height: 20px;
        height: 20px;

      }
    }

  }

  .footnote {
    margin-top: 10px;
    width: auto;
    text-align: left;

    .grid {
      width: 100%;
    }

    .column {
      height: 40px;
      vertical-align: bottom;
      padding: 0px;
      margin: 0px;
    }


    p {
      line-height: 40px;
      font-size: 13px;
      padding: 0px;
      margin-left: 10px;
      margin-right: 10px;
      text-align: right;

    }


  }


  .buttons {

    display: flex;

    .button {
      font-size: 14px;
      background-color: #D6D6D6 !important;;
      font-weight: 100;
      border: 1px solid;
      min-width: 150px;
      color: white;
      font-weight: 600;
    }

    .button.active {
      background: #4F8BA5 !important;
    }
  }

}

.chart.tooltip {
  padding: 15px;
  border-radius: 4px;
  opacity: 1;
  font-family: Roboto;
  line-height: 28px;
  font-size: 16px;
  color: #1e1e1e;
  background-color: #f7f7f7 !important;
  border: 1px solid #dddddd !important;
  line-height: 1.5;

  hr {
    margin-left: -15px;
    margin-right: -15px;
  }

  b {
    color: #1e1e1e !important;
  }

  table, th, td {
    border: 1px solid;
    border-collapse: collapse;
    padding: 5px;
    border-color: #dddddd;
  }
}

.chartView {
  margin-bottom: 20px;
}

