.container.tcdi.footer {
  margin-top: 100px;
  line-height: 16px !important;

  .footer-cover{
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    padding-top: $base-unit * 3;
    padding-bottom: $base-unit * 3;
    max-height: 84px;
    a{
      color: $ke-footer-link-color;
    }
    .wp-block-columns{
      margin-bottom: 0;
    }
    .wp-block-image{
      display: block;
    }
  }
}
